import { Icon, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import type { ListProps } from '@chakra-ui/react';
import { FC } from 'react';
import { AiFillGithub, AiFillLinkedin, AiFillFacebook } from 'react-icons/ai';

const socials = [
  {
    icon: AiFillGithub,
    url: 'https://github.com/amit-sawhney',
    title: 'GitHub',
  },
  {
    icon: AiFillLinkedin,
    url: 'https://www.linkedin.com/in/amit-m-sawhney/',
    title: 'LinkedIn',
    color: 'linkedin.700',
  },
  {
    icon: AiFillFacebook,
    url: 'https://www.facebook.com/amitmsawhney',
    title: 'Facebook',
    color: 'facebook.700',
  },
];

export const SocialsFooter: FC<ListProps> = ({ ...rest }) => (
  <UnorderedList
    listStyleType="none"
    ml={0}
    display="flex"
    justifyContent="space-around"
    {...rest}
  >
    {socials.map(({ icon, url, title, color }) => (
      <ListItem key={title}>
        <Link aria-label={title} href={url} isExternal>
          <Icon color={color} boxSize={10} as={icon} />
        </Link>
      </ListItem>
    ))}
  </UnorderedList>
);
