import { Link } from '@chakra-ui/react';
import type { LinkProps } from '@chakra-ui/react';
import { FC } from 'react';

interface TextLinkProps extends LinkProps {
  text: string;
}

export const TextLink: FC<TextLinkProps> = ({ text, ...rest }) => (
  <Link isExternal fontWeight="bold" {...rest}>
    {text}
  </Link>
);
