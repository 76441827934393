import { Box, Divider, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useEffect } from 'react';

import picture from '../assets/picture.jpg';
import { Page, SocialsFooter } from '../layout';
import { TextAccent, TextLink } from '../components';

export const Home = () => {
  useEffect(() => {
    document.title = 'Home | Amit Sawhney';
  }, []);

  return (
    <Page title="Home">
      <Flex
        h={`100vh`}
        w={['90%', '80%', '80%', '50%']}
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        margin="auto"
      >
        <Image
          h={['180px', '210px']}
          borderRadius="50%"
          src={picture}
          alt=""
          mb={10}
        />
        <Heading as="h1" size="xl">
          Amit Sawhney
        </Heading>
        <Box w={['100%', '80%']} textAlign="center" mt={5}>
          <Text fontSize="lg">
            I am currently studying Computer Science and Math at the{' '}
            <TextAccent
              text="University of Illinois @ Urbana-Champaign"
              color="#E84A27"
            />
            . I strive to build reliable and accessible software that can
            improve the lives of communities in need.
          </Text>
          <Text fontSize="lg" mt={3}>
            Currently, I work at <TextAccent color="#635bff" text="Stripe" /> as
            a Software Engineering Intern, and during school, I am heavily
            involved in{' '}
            <TextLink
              href="https://uiuc.hack4impact.org/"
              color="#145DA3"
              text="Hack4Impact"
            />
            {/* , */}
            {/* but feel free to read more about my{' '}
            <Link fontWeight="bold" href="/projects">
              Projects
            </Link>{' '}
            and{' '}
            <Link fontWeight="bold" href="/experiences">
              Experiences
            </Link> */}
            .
          </Text>
          <Divider mt={10} />
          <SocialsFooter mt={10} />
        </Box>
      </Flex>
    </Page>
  );
};
