import { chakra } from '@chakra-ui/react';
import type { PropsOf } from '@chakra-ui/react';
import { FC, useEffect, ReactNode } from 'react';

interface PageProps extends PropsOf<'main'> {
  title: string;
  children: ReactNode;
}

export const Page: FC<PageProps> = ({ title, children, ...rest }) => {
  useEffect(() => {
    document.title = `${title} | Amit Sawhney`;
  }, [title]);

  return (
    <chakra.main id={title} {...rest}>
      {children}
    </chakra.main>
  );
};
