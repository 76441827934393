import { chakra, PropsOf } from '@chakra-ui/react';
import { FC } from 'react';

interface TextAccentProps extends PropsOf<'span'> {
  text: string;
  color: string;
}

export const TextAccent: FC<TextAccentProps> = ({ text, color, ...rest }) => (
  <chakra.span fontWeight="bold" color={color} {...rest}>
    {text}
  </chakra.span>
);
